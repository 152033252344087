import { FC, PropsWithChildren } from "react"
import { Loader as PaxLoader } from "@partner-tech/pax-ui-kit"

interface CenteredLoaderProps extends PropsWithChildren {
    label?: string
    loading?: boolean
    className?: string
}

export const LoadingSpinner: FC<CenteredLoaderProps> = ({ label, className, children, loading = true }) => {
    return (
        <div className="flex h-full justify-center">
            <PaxLoader loading={loading} label={label} variant="indeterminate" className={className}>
                {children}
            </PaxLoader>
        </div>
    )
}
