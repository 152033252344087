import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { config } from "@/config"
import {
    AnalyticsDateRange,
    ClientCredentials,
    CreateMerchant,
    Merchant,
    MerchantConfig,
    MerchantsFilters,
    Order,
    OrderCancellationRateResponse,
    OrderHistoryResponse,
    OrderProcessingTimeResponse,
    OrdersFilters,
    OrderVolumeResponse,
    PaginatedResponse,
    SalesChannel,
} from "@/services/types"

interface ErrorResponse {
    type: string
    status: number
    detail: string
    field_name: string | null
}

export class ApiError extends Error {
    constructor(
        message: string,
        public readonly response?: ErrorResponse,
        public readonly status?: number,
    ) {
        super(message)
        this.name = "ApiError"
    }
}

const filtersToParams = (filters: Record<string, any>): URLSearchParams => {
    const params = new URLSearchParams()
    Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                value.forEach((v) => params.append(key, v.toString()))
            } else {
                params.append(key, value.toString())
            }
        }
    })
    return params
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${config.settings.API_URL}/api`,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
    },
})

const makeAuthenticatedApiCall = async <T>(
    method: "GET" | "POST" | "PUT",
    url: string,
    getAccessTokenSilently: () => Promise<string>,
    config: Partial<AxiosRequestConfig> = {},
): Promise<T> => {
    try {
        const token = await getAccessTokenSilently()
        const requestConfig: AxiosRequestConfig = {
            ...config,
            method,
            url,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
        }
        const response = await axiosInstance(requestConfig)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<ErrorResponse>
            const errorResponse = axiosError.response?.data
            throw new ApiError(
                errorResponse?.detail || axiosError.message || "An error occurred",
                errorResponse,
                axiosError.response?.status,
            )
        }
        throw new ApiError("An unexpected error occurred")
    }
}

type ClientCredentialResponse = { data: ClientCredentials[] }

export const useAuthenticatedApi = () => {
    const { getAccessTokenSilently } = useAuth0()

    return {
        getMerchants: (filters: MerchantsFilters) => {
            return async (): Promise<PaginatedResponse<Merchant[]>> => {
                const params = filtersToParams(filters)
                const config: Partial<AxiosRequestConfig> = params.toString() ? { params } : {}
                return await makeAuthenticatedApiCall<PaginatedResponse<Merchant[]>>(
                    "GET",
                    `/internal/merchants`,
                    getAccessTokenSilently,
                    config,
                )
            }
        },

        postMerchant: async (data: CreateMerchant): Promise<CreateMerchant> => {
            return await makeAuthenticatedApiCall<CreateMerchant>("POST", `/internal/merchants`, getAccessTokenSilently, {
                data,
                timeout: 60000, // Long backend call, because it creates objects in Auth0
            })
        },

        getMerchantDetails: (merchantCode: string) => {
            return async (): Promise<MerchantConfig> => {
                return await makeAuthenticatedApiCall<MerchantConfig>("GET", `/internal/merchants/${merchantCode}`, getAccessTokenSilently)
            }
        },

        putMerchantDetails: async (merchantCode: string, data: MerchantConfig) => {
            return await makeAuthenticatedApiCall<MerchantConfig>("PUT", `/internal/merchants/${merchantCode}`, getAccessTokenSilently, {
                data,
            })
        },

        getClientCredentials: (merchantCode: string) => {
            return async (): Promise<ClientCredentials> => {
                const response = await makeAuthenticatedApiCall<ClientCredentialResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/auth/client-credentials`,
                    getAccessTokenSilently,
                )
                return response.data[0]
            }
        },

        rotateClientSecret: async (merchantCode: string, clientId: string) => {
            return await makeAuthenticatedApiCall<ClientCredentials>(
                "POST",
                `/internal/merchants/${merchantCode}/auth/client-credentials/${clientId}/rotate-secret`,
                getAccessTokenSilently,
            )
        },

        getSalesChannels: (merchantCode: string) => {
            return async (): Promise<SalesChannel[]> => {
                return await makeAuthenticatedApiCall<SalesChannel[]>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-channels`,
                    getAccessTokenSilently,
                )
            }
        },

        getOrders: (merchantCode: string, filters: OrdersFilters) => {
            return async (): Promise<PaginatedResponse<Order[]>> => {
                const params = filtersToParams(filters)
                const config: Partial<AxiosRequestConfig> = params.toString() ? { params } : {}

                return await makeAuthenticatedApiCall<PaginatedResponse<Order[]>>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders`,
                    getAccessTokenSilently,
                    config,
                )
            }
        },

        getOrderDetails: (merchantCode: string, orderId: string) => {
            return async (): Promise<Order> => {
                return await makeAuthenticatedApiCall<Order>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders/${orderId}`,
                    getAccessTokenSilently,
                )
            }
        },

        getOrderVolume: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderVolumeResponse> => {
                return await makeAuthenticatedApiCall<OrderVolumeResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/order-volume`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getCancellationRate: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderCancellationRateResponse> => {
                return await makeAuthenticatedApiCall<OrderCancellationRateResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/cancellation-rate`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getProcessingTime: (merchantCode: string, dateRange: AnalyticsDateRange) => {
            return async (): Promise<OrderProcessingTimeResponse> => {
                return await makeAuthenticatedApiCall<OrderProcessingTimeResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/analytics/processing-time`,
                    getAccessTokenSilently,
                    { params: dateRange },
                )
            }
        },

        getOrderHistory: (merchantCode: string, orderCode: string) => {
            return async (): Promise<OrderHistoryResponse> => {
                return await makeAuthenticatedApiCall<OrderHistoryResponse>(
                    "GET",
                    `/internal/merchants/${merchantCode}/sales-orders/${orderCode}/history`,
                    getAccessTokenSilently,
                )
            }
        },
    }
}
